import {Box, useTheme} from '@mui/material';
import {PageSeo, PageType} from '../components/common/container';
import {STORAGE, URL_SERVER_INTERNAL} from '../utils/settings';

import CampaignHome from '../components/campaign/campaignHome';
import {CampaignOverview} from '../types';
import ComingSoon from '../components/home/comingSoon';
import FoundersHome from '../components/home/founders';
import Future from '../components/home/future';
import {GetStaticProps} from 'next';
import History from '../components/home/history';
import How from '../components/home/how';
import RevealCurtain from '../components/home/revealCurtain';
import TitleHome from '../components/home/title';
import What from '../components/home/what';
import Why from '../components/home/why';
import {getCampaignsHome} from '../utils/campaign';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

export default function Index(props: IndexStaticProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    // const campaign = getCampaignHome(props.data);
    const campaigns = getCampaignsHome(props.data);

    const bg = STORAGE + '/bg/' + (isDark ? 'title.svg?v=3' : 'title.svg?v=3');

    const gradient = isDark
        ? 'linear-gradient(#1c1c1e, #141414)'
        : 'linear-gradient(white, #f0f0f0)';

    // const hasCampaign = Boolean(campaign);
    const hasCampaign = campaigns.length > 0;

    return (
        <>
            <PageSeo page={PageType.HOME} />
            <RevealCurtain />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundImage: 'url(' + bg + '), ' + gradient,
                    paddingBottom: '200px',
                    marginBottom: '-200px',
                }}
            >
                <TitleHome />
                {campaigns.length == 0 && <History withoutActiveCampaign />}
                {campaigns.map((c) => (
                    <CampaignHome campaign={c} key={c.campaign_id} />
                ))}
                {campaigns.length > 0 && <History />}
            </Box>
            <ComingSoon />
            <What />
            <FoundersHome />
            <Why />
            <Future />
            <How />
        </>
    );
}

export type IndexStaticProps = {
    data: CampaignOverview[];
};

export const getStaticProps: GetStaticProps<IndexStaticProps> = async (context) => {
    const translations = await serverSideTranslations(context.locale, ['common']);
    try {
        // console.log('context.locale = ', context.locale);

        let url = URL_SERVER_INTERNAL + '/campaign';
        let requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const result = await fetch(url, requestOptions);
        const json = await result.json();

        return {
            props: {
                ...translations,
                data: json,
            },
            revalidate: 5,
        };
    } catch (e) {
        console.error('getServerSideProps::error ', e);
        return {
            props: {
                ...translations,
                data: [],
            },
            revalidate: 5,
        };
    }
};
